import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/enviroment';
import { IReset2FAStatus, ITwoFARequest, ITwoFARequestsRes } from '../interfaces/twoFARequests';
import { StatusCounts } from '../../../layouts/components/side-menu/assets/live-point';

@Injectable({
  providedIn: 'root'
})
export class TwoFARequestsService {

  private _http = inject(HttpClient);
  public statusCounts = new BehaviorSubject<IReset2FAStatus>({} as IReset2FAStatus);


  getRequests(page: number, limit: number, email?: string): Observable<ITwoFARequestsRes> {
    return this._http.get<ITwoFARequestsRes>(`${environment.apiUrl}/v1/user/all-2fa-requests?page=${page}&limit=${limit}${email ? `&email=${email}` : ''}`);
  }
  getRequestDetails(id: string): Observable<ITwoFARequest> {
    return this._http.get<ITwoFARequest>(`${environment.apiUrl}/v1/user/2fa-requests/${id}`);
  }
 
  getReset2FARequestCounts(): Observable<IReset2FAStatus> {
    return this._http.get<IReset2FAStatus>(`${ environment.apiUrl }/v1/user/2fa-status-counts`);
  }
}
